export const apiRoute = Object.freeze({


    updatedatosusuario:"api/keycloak/usuarios/updatedatosusuario",    
    usuarios_enable:"api/keycloak/usuarios/changestatus",
    usuarios_sessions:"api/keycloak/usuarios/usersessions",
    roles:"api/keycloak/roles",
    users: "api/users",
    getUser:"api/getUser",
    userAuthRoute: "api/auth",
    usuarios_entidades:"api/keycloak/showByUserClient",
    usuarios:"api/keycloak/usuarios",
    solicitudUsuario:"api/keycloak/solicitud_usuario",
    solicitudUsuarioPublic:"api/keycloak/solicitud_usuario_public",
    solicitudUsuarioChangeStatus:"api/keycloak/solicitud_usuario/changestatus",
    identifiertypes: "api/keycloak/persons/identifiertypes/public",
    genders: process.env.VUE_APP_API_URL_PERSONS+"persons/genders",
    maritalstatuses: process.env.VUE_APP_API_URL_PERSONS+"persons/maritalstatuses",
    persons: process.env.VUE_APP_API_URL_PERSONS+"persons",
    personsAggregator: process.env.VUE_APP_API_URL_PERSONS+"personsaggregator",
    personRelationship: process.env.VUE_APP_API_URL_PERSONS+"persons/relationships",
    paises: "api/domicilios/paises",
    clientes: process.env.VUE_APP_API_URL+"/api/clientes",
    localidades: process.env.VUE_APP_API_URL+"/api/localidades",
    provincias: "api/provincias/public",
    notificacionesByUser:"api/notificaciones/unreadsByUser",
    notificacionesUsuarios:"api/notificaciones/notificationsByUser",
    notificacionesChangeStatus:"api/notificaciones/notificaciones_usuarios/changestatus",
    entidades:"api/entidades/entidades",
    stockEstampillas:"",
    stockEstampillasChangeStatus:"",
    /** url para el mapeo de stock de productos */
    asignarLote: "api/stock/asignarLote",
    stockByEntidadByProducto: "api/stock/lotes/entidad/producto",
    stockLotes: "api/stock/lotes",
    stockSerieByEntidadByProducto: "api/stock/lotes_formularios/entidad/producto",
    stockLotesFormularios: "api/stock/lotes_formularios",
    transacciones: process.env.VUE_APP_API_URL+"/api/transacciones",
    transaccionDetalle: process.env.VUE_APP_API_URL + "/api/transacciones/{id}/transaccion_detalle",
    uploadimageprofile:"api/keycloak/usuarios/uploadimageprofile",
    viewimageprofile:"api/keycloak/usuarios/viewimageprofile",
    tipo_entidades:"api/entidades/tipo_entidades",
    archivos:"api/archivos",
    archivos_enable:"api/archivos/changestatus",
    archivos_removefile:"api/archivos/removefile",
    archivos_getfile:"api/archivos/getfile",
    historialByProducto: "api/stock/historial/producto",
    bussinessPartnerSL: "api/servicelayer/bussinesspartners/TST_MJV20", // crea el usuario de SAP en servicelayer
    bussinessPartnerClient: "api/hanaxs/bussinesspartnerclient", //trae la info del usuario en SAP
    bussinessPartnerIsClient: "api/hanaxs/public/isclient", //verifica si existe el usuario en SAP
    solicitudUsuarioApprove : "api/keycloak/solicitud_usuario/approveUserRequest",
    elementosRegistrales: "api/servicelayer/elementosregistrales", // consulta los items de elem registrales
});
