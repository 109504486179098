import Vue from "vue";
import App from "./App.vue";


import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import VueConfirmDialog from "vue-confirm-dialog";
import Keycloak from "keycloak-js";
import AxiosApi from "./plugins/axiosApi";

import ClientVar from "./utils/ClientVar";
import Can from "./utils/Can";

import Rules from "./utils/Rules.js";
import CrudRequest from "./utils/CrudRequest.js";
import formatters from "./utils/formatters";
//import Permissions from "@/mixins/Permissions";
import store from "./store";

import JsonExcel from "vue-json-excel";
import swal from "sweetalert2";

import VuetifyMoney from "vuetify-money";

import { VueMaskDirective } from "v-mask";
import moment from "moment";

import "animate.css";
import "./assets/styles/global.css";
import router from "./router";

Vue.prototype.moment = moment;

import VueToastr from "vue-toastr";
import startPusher from "./utils/Pusher";

// use plugin
Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
});

window.Swal = swal;

Vue.use(VuetifyMoney);
Vue.directive("mask", VueMaskDirective);
Vue.use(ClientVar);
Vue.use(Can);
Vue.use(Rules);
Vue.use(CrudRequest);
Vue.use(formatters);
//Vue.mixin(Permissions);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
//Vue.component('f01-wizard-component', require('./views/tramites/components/F01Wizard.vue').default);
Vue.component(
  "filter-component",
  require("./views/components/Filter.vue").default
);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  Vue.prototype.$previousRoute = from;
  next();
});

const initApp = () => {
  const currentPath = window.location.pathname;



  const filteredRoute = findRoutesByPath(router.options.routes, currentPath);


  if (!filteredRoute || filteredRoute.meta.requireAuth) {
    initializeKeycloak();
  } else {
    startVueApp();
    Vue.prototype.$axiosApi = new AxiosApi();
  }
};

const initializeKeycloak = () => {
  let client = "";
  let urlKeycloak = process.env.VUE_APP_KEYCLOAK_URL;
  client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
  let initOptions = {
    url: urlKeycloak,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: client,
    onLoad: "login-required",
  };

  let keycloak = new Keycloak(initOptions);
  keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((auth) => {
      if (!auth) {
        window.location.reload();
      } else {
        store.commit('SET_USER', keycloak);
        startVueApp(keycloak);


      }

      Vue.prototype.$keycloak = keycloak;
      Vue.prototype.$token = keycloak.token
      Vue.prototype.$axiosApi = new AxiosApi(Vue.prototype.$token);

      setInterval(refreshToken, 50000);

      //configureRouter();
    })
    .catch((err) => {
      console.log("Authentication Failed", err);
    });
};

const configureRouter = () => {
  router.beforeEach((to, from, next) => {
    if (to.meta.isAuthenticated) {
      const basePath = window.location.toString();
      if (!Vue.prototype.$keycloak.authenticated) {
        Vue.prototype.$keycloak.login({
          redirectUri: basePath.slice(0, -1) + to.path,
        });
      } else {
        Vue.prototype.$keycloak.updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.log("Token 1 refreshed:"+refreshed);
          } else {
            console.log("Token 1 not refreshed");
          }


            next();
          })
          .catch((err) => {
            console.error("Failed to update token", err);
          });
      }
    } else {
      next();
    }
  });
};

const refreshToken = () => {
        //if(Vue.prototype.$keycloak.isTokenExpired())
        //{
        Vue.prototype.$keycloak.updateToken(70).then((refreshed)=>{
          let t = Vue.prototype.$keycloak.token.toString();
          let endtoken = t.substring(t.length-5,t.length);

          if (refreshed) {
            console.log('Token:'+endtoken+', refreshed:'+refreshed+', valid for '  + Math.round(Vue.prototype.$keycloak.tokenParsed.exp + Vue.prototype.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            //console.log(t.substring(t.length() - 5));

          } else {
            console.log('Token:'+endtoken+', refreshed:'+refreshed+', valid for '  + Math.round(Vue.prototype.$keycloak.tokenParsed.exp + Vue.prototype.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
          }
        }).catch(()=>{
          console.log('Failed to refresh token');
        });
      /*}
      else
      {
        console.log('Token not refreshed, valid for '
        + Math.round(Vue.prototype.$keycloak.tokenParsed.exp + Vue.prototype.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }*/
};

const startVueApp = (keycloak) => {
  const app = new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: (h) => h(App, { props: { keycloak: keycloak } }),
        data:{
          notifications: [],
        }
      }).$mount("#app");

      startPusher(app,keycloak);



};

function findRoutesByPath(routes, currentPath) {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    /*if (route.path === currentPath) {
      return route;
    } else if (route.children) {
      const childOption = findRoutesByPath(route.children, currentPath);
      if (childOption) {
        return childOption;
      }
    }*/
    // Convertir la ruta con parámetros dinámicos a una expresión regular
    const paramRegex = new RegExp('^' + route.path.replace(/:[^\s/]+/g, '([\\w-]+)') + '$');

    if (paramRegex.test(currentPath)) {
      return route;
    } else if (route.children) {
      const childOption = findRoutesByPath(route.children, currentPath);
      if (childOption) {
        return childOption;
      }
    }
  }
  return null;
}



initApp();


