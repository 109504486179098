export function setSaldo(state, saldo) {
  state.itemsSaldo = saldo;
}

export function addCliente(state, cliente) {
  state.clientesAnmac.unshift(cliente);
}

export function setClienteAnmac(state, cliente) {
  state.clienteAnmac = cliente;
}

export function editCliente(state, cliente) {
  console.log("editando");
}

export function setEstampillas(state, estampillas) {
  estampillas.forEach((estampillas) => {
    const stock =
      parseInt(estampillas.serialEnd) - parseInt(estampillas.serialStart);
    estampillas.stock = stock;
  });
  state.estampillasAnmac = estampillas;
}

export function vaciarAnmac(state) {
  state.clienteAnmac = {};
}
