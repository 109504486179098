import { find, filter } from "lodash";

//manejo de productos en el carrito
export function addProducto(state, producto) {
  const productoEnCarrito = find(state.carrito, { id: producto.id });

  //si no encuentra el producto por id lo agregar
  if (!productoEnCarrito) {
    state.carrito.unshift(producto);
    totalCarrito(state);
    return;
  }

  //si el producto ya existe extraemos las cantidades y corregimos subtotales
  const nuevaCantidad = producto.cantidad * 1 + productoEnCarrito.cantidad * 1;
  const nuevoSubtotal = producto.precio * nuevaCantidad;
  productoEnCarrito.cantidad = nuevaCantidad;
  productoEnCarrito.subtotal = nuevoSubtotal;
  state.carrito = filter(state.carrito, ({ id }) => id !== producto.id);
  state.carrito.unshift(productoEnCarrito);
  totalCarrito(state);
}

//seteo comision tramite
export function setTramite(state, item) {
  const { tipogasto, gasto } = item;
  state.tramitePago = {
    tipogasto,
    gasto,
  }
};

export function editProducto(state, producto) {
  const productoEnCarrito = find(state.carrito, { id: producto.id });
  productoEnCarrito.cantidad = producto.cantidad * 1;
  productoEnCarrito.precio = producto.precio;
  productoEnCarrito.subtotal = producto.precio * 1 * productoEnCarrito.cantidad;
  state.carrito = filter(state.carrito, ({ id }) => id !== producto.id);
  state.carrito.unshift(productoEnCarrito);
  totalCarrito(state);
}

export function removeProducto(state, producto) {
  state.carrito = filter(state.carrito, ({ id }) => id !== producto.id);
  totalCarrito(state);
}

export function vaciarCarrito(state) {
  state.carrito = [];
  state.tramitePago = {},
    state.total = 0;
}

const totalCarrito = (state) => {
  let total = 0;
  state.carrito.forEach((producto) => {
    total = (Number(total) + Number(producto.subtotal));

  });
  state.total = Number(total);
};

export function totalCarritoSSC (state, total) {
  state.total = Number.total;
}
