export default {

  install(Vue, options) {
      //evaluar permisos
      Vue.prototype.$can = function (permissionsName) {
        if (typeof this.$store.state.user.permisos !== "undefined"){
          //permiso_dev para desarrollar
          if (permissionsName.some(valor => valor.includes("permiso_dev_"))) {
              return true;
          }

          const permission = permissionsName.some((value) =>

            this.$store.state.user.permisos.includes(value)
          );


          return permission;
        }

        return false;

      }
      //evaluar roles
      Vue.prototype.$is = function ( roleName) {
        if (typeof this.$store.state.user.roles !== "undefined"){
          const role = roleName.some((value) =>
            this.$store.state.user.roles.includes(value)
          );

          return role;
        }

        return false;

      }

      //evaluo las propiedades que se setearon de SAP
      Vue.prototype.$isEnabledProperty = function (properties) {                
        if (typeof this.$store.state.user.sapProperties !== "undefined"){                   
          const prop = properties.some((value) =>            
              this.$store.state.user.sapProperties["Properties" + value]            
          );
          return prop;
                   
        }

        return false;

      }

  }
}
