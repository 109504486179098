import axios from "axios";
import {apiRoute} from "@/helper/apiRoute";



export default class AxiosApi {
    constructor(token) {
        const axiosApi =   axios.create({
          withCredentials: false,
        });
        this.axiosApi = axiosApi;
        this.token = token;
        this.routes = apiRoute;
    }

    /**
     *
     * @param headersOption
     * @returns {{headers: (*&{Authorization: (*|string)})}}
     */
        getRequestOptions(headersOption) {



          let headersReq = {
                  accept: 'application/json',
                  "Authorization":  `Bearer ${this.token}`,
                  "Access-Control-Allow-Origin": "*",
                  "Accept": "*/*",
                  "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                  ...headersOption
          };





          return {
              headers: headersReq
          };
      }

      /**
       *
       * @param {*} url
       * @param {*} methodRequest
       * @param {*} dataInput
       * @param {*} additionalHeaders
       * @returns
       */

      sendRequest(url, methodRequest, dataInput = null, additionalHeaders = {}) {
          let baseUrl = '';
          //console.log(dataInput);
          //baseUrl = (location.host == 'localhost:8081')?process.env.VUE_APP_API_URL_LOCAL:process.env.VUE_APP_API_URL;
          baseUrl = process.env.VUE_APP_API_URL;

          const requestOptions = this.getRequestOptions(additionalHeaders);

          const dataForm = dataInput ? JSON.stringify(dataInput) : null;
          console.log("dataForm");
          console.log(dataForm);
          const promise =  this.axiosApi({baseURL: baseUrl+'/', url: url, method: methodRequest, data: dataForm, ...requestOptions})

          return promise.then((response) => response);
      }



      sendRequestPdf(url, methodRequest, dataInput = null, additionalHeaders = {}) {
        let baseUrl = '';

        baseUrl = process.env.VUE_APP_API_URL;

        const requestOptions = this.getRequestOptions(additionalHeaders);

        this.axiosApi({ baseURL: baseUrl+'/', url: url, method: methodRequest, responseType: "blob" , ...requestOptions})
          .then((response) =>{
              var blob = new Blob([response.data], { type: "application/pdf" });
              const url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
              const link = document.createElement("a");
              link.target = "_blank";

              link.href = url;

              document.body.appendChild(link);
              link.click();
          }
      );




    }



    sendRequestPdfLoad(url, methodRequest, dataInput = null, additionalHeaders = {}) {
      let baseUrl = '';

      baseUrl = process.env.VUE_APP_API_URL;

      const requestOptions = this.getRequestOptions(additionalHeaders);

      const promise =  this.axiosApi({ baseURL: baseUrl+'/', url: url, method: methodRequest, responseType: "blob" , ...requestOptions});

         return promise.then((response) => response);

    }



      sendRequestExternal(url, methodRequest, dataInput = null, additionalHeaders = {}) {
        const requestOptions = this.getRequestOptions(additionalHeaders);

        const dataForm = dataInput ? JSON.stringify(dataInput) : null;

        const promise =  this.axiosApi({url: url, method: methodRequest, data: dataForm, ...requestOptions})
        return promise.then((response) => response);
    }

      sendRequestFile(url, methodRequest, dataInput = null, additionalHeaders = {}) {
        let baseUrl = '';
        //console.log(dataInput);
        //baseUrl = (location.host == 'localhost:8081')?process.env.VUE_APP_API_URL_LOCAL:process.env.VUE_APP_API_URL;
        baseUrl = process.env.VUE_APP_API_URL;

        const requestOptions = this.getRequestOptions(additionalHeaders);


        const dataForm = dataInput;
        const promise =  this.axiosApi({baseURL: baseUrl+'/', url: url, method: methodRequest, data: dataForm, ...requestOptions})
        return promise.then((response) => response);
    }

      /**
       *
       * @param {*} route
       * @returns
       */
      get(route) {
          const url = this.routes[route];
          return this.sendRequest(url, 'get')
              .then((data) => data);
      }

      getWithRequest(route, form){
        //return this.sendRequest(url, 'post', data, additionalHeader)
        console.log(form);
        const url = this.routes[route];
          return this.sendRequest(url, 'get', form)
              .then((data) => data);
      }

      getExternal(route) {

          const url = this.routes[route];
          return this.sendRequestExternal(url, 'get')
              .then((data) => data);
      }

      getByQuery(route, query) {
        const url = this.routes[route]+"?" +query;
        return this.sendRequest(url, 'get')
            .then((data) => data);
      }

      getById(route,id) {
        const url = this.routes[route]+"/"+id;

        return this.sendRequest(url, 'get')
            .then((data) => data);
      }
      getByCriteria(route,criteria) {
        const url = this.routes[route]+"/"+criteria;
        return this.sendRequest(url, 'get')
            .then((data) => data);
      }
      getDownload(route,criteria) {
        let baseUrl = process.env.VUE_APP_API_URL;
        let additionalHeaders = '';
        const url = this.routes[route]+"/"+criteria;
        const requestOptions = this.getRequestOptions(additionalHeaders);
        const promise =  this.axiosApi({baseURL: baseUrl+'/', url: url, method: 'GET',responseType: "blob",...requestOptions})

        return promise.then((response) => response);
      }
      /**
       *
       * @param {*} route
       * @param {*} userForm
       * @returns
       */

      post(route,userForm) {
        const url = this.routes[route];
        let data = userForm;
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'post', data, additionalHeader)
            .then((data) => data);
      }

      postExternal(route,userForm) {
        const url = this.routes[route];
        let data = userForm;
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequestExternal(url, 'post', data, additionalHeader)
            .then((data) => data);
      }

      postFile(route,userForm) {
        const url = this.routes[route];
        let data = userForm;
        let additionalHeader = {'Content-Type': 'multipart/form-data'};
        return this.sendRequestFile(url, 'post', data, additionalHeader)
            .then((data) => data);
      }

      putFile(route,userForm,id) {
        const url = this.routes[route]+'/'+id;
        let data = userForm;
        let additionalHeader = {'Content-Type': 'multipart/form-data'};
        return this.sendRequestFile(url, 'post', data, additionalHeader)
            .then((data) => data);
      }

      put(route, id, userForm) {
        const url = this.routes[route]+'/'+id;

        let data = userForm;
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequest(url, 'put', data, additionalHeader)
            .then((data) => data);
        /*
        const url = process.env.VUE_APP_API_URL+'/'+this.routes[route]+'/'+id;
        let additionalHeader = {'Content-Type': 'application/json'};
        let requestOptions = this.getRequestOptions(additionalHeader);
        const promise = axios
            .post(url, {
              _method: "PUT",
              data: userForm,
              headers: requestOptions,
            })



          return promise.then((response) => response).then((data) => data);

        */
      }

      putExternal(route, userForm, id=null) {

        const url = this.routes[route]+((id)?'/'+id:'');
        let data = userForm;
        let additionalHeader = {'Content-Type': 'application/json'};
        return this.sendRequestExternal(url, 'put', data, additionalHeader)
            .then((data) => data);

      }

      delete(route,id){

        const url = this.routes[route]+"/"+id;
        return this.sendRequest(url, 'delete')
            .then((data) => data);
			}

      pdfExport(route,id) {

      }
      async pdfLoad(route,id, download=false) {
        try {
          const url = this.routes[route];
          const response = await this.sendRequestPdfLoad(url + "/" + id, "GET");
          const blob = new Blob([response.data], { type: "application/pdf" });
          const blobUrl = URL.createObjectURL(blob);

          if(download)
          {
                  // Obtengo el nombre del archivo del encabezado de respuesta
                  const contentDisposition = response.headers["content-disposition"];
                  const matches = /filename="(.+?)"/.exec(contentDisposition);
                  const filename = matches ? matches[1] : null;


                  // Si es DDJJ, creo un enlace para descargar el archivo con el nombre
                  const link = document.createElement("a");
                  link.href = blobUrl;
                  link.download = filename;

                  // Simula un clic en el enlace para iniciar la descarga
                  link.click();
          }
          else
          {
            return blobUrl;
          }


        } catch (error) {
            throw error;
        }
      }

      async zipLoad(route,id) {
        try {
            const url = this.routes[route];
            const response = await this.sendRequestPdfLoad(url+"/"+id, 'GET');
            if(response){
              const contentDisp = response.headers.get('Content-Disposition');
              if(response.status == 200){
                let fileName = this.getFileName(contentDisp);
                fileName = (fileName)?fileName:'file_'+id+'_'+Date.now();
                var fileUrl = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
                var fileLink = document.createElement("a");
                fileLink.href = fileUrl;
                fileLink.setAttribute("download", fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
              }
              else{
                return response;
              }
            }
            /*
            let fileName = 'file_'+Date.now();
            var fileUrl = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
            var fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();        */
        } catch (error) {
          Swal.close();
            throw error;
        }
      }

      getFileName(disposition){
        var filename = "";
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    }

    getImage(route) {
      const url = this.routes[route];
      let additionalHeader = {
        responseType: 'blob',
      };
      return this.sendRequest(url, 'get',null,additionalHeader)
          .then((data) => data);
  }



  }
