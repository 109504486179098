import { apiRoute } from '../../helper/apiRoute';
import AxiosApi from './../../plugins/axiosApi.js';
import axios from 'axios';
import Vue from 'vue';


const axiosApi = new AxiosApi();

export default {
  
  async fetchClientesAduana({ commit }){
    try {

      // Llamada a la api clientes
      const axiosApi = new AxiosApi(Vue.prototype.$token);
      const response = await axiosApi.getExternal('clientes');

      if (response.status !== 200) {
        throw new Error('Error en la respuesta de la api clientes: ' + response.status);
      }

      let jsonData = await response.data.data;
      clienteData(commit, jsonData);

    } catch (error) {
      console.error('Error al obtener los clientes de aduana:', error);
    }
  },

  actualizarClienteLocal({ commit }, clienteAduana) {
      commit('setClienteLocal', clienteAduana);
    },
    
    actualizarSourceVentaElementosAduana({ commit }, valor) {
      commit('setSourceVentaElementosAduana', valor);
    },

    resetSourceVentaElementosAduana(state) {
      state.sourceVentaElementosAduanaSt = null;
    },
  };

function clienteData(commit, jsonData) {
  const dataClientesAduana = [];

  jsonData.forEach(item => {
    let personData;
    try {
      personData = JSON.parse(item.cliente_json);
    } catch (error) {
      console.error('Error al traer cliente_json:', error);
      return;
    }
    
    const clienteObj = {
      id: item.id,
      name: personData.firstName || '',
      lastName: personData.lastName || '',
      businessName: personData.businessName || '',
      documento: personData.identifiers?.[0]?.identifier || '',
      localidad: personData.addresses?.[0]?.address?.location || '',
      phone: personData.phones?.[0]?.phoneNumber || '',
      email: personData.emails?.[0]?.email || '',
    };

    dataClientesAduana.push(clienteObj);
  });
  //console.log("CLIENTES: " + JSON.stringify(dataClientesAduana));
  commit("aduana/setClientesAduana", dataClientesAduana, { root: true });
}