<template>
  <router-view :key="$route.path" />
</template>
<vue-confirm-dialog></vue-confirm-dialog>
<script>
export default {
  name: "App",
};
</script>
<style scoped>
.swal2-container {
  z-index: 999999 !important;
}
.swal2-content {
  z-index: 999999 !important;
}
</style>
