import { apiRoute } from '../../helper/apiRoute';
import AxiosApi from './../../plugins/axiosApi.js';
import Vue from "vue";

export default {
  async fetchTransacciones({ commit }) {
    try {
      const axiosApi = new AxiosApi(Vue.prototype.$token);
      const response = await axiosApi.getExternal("transacciones");

      if (response.status !== 200) {
        throw new Error('Error en la respuesta de la api transacciones: ' + response.status);
      }

      const jsonData = response.data;
      transaccionData(commit, jsonData);

    } catch (error) {
      console.error('Error al obtener las transacciones:', error);
    }
  },

  async fetchTransaccionDetalle({ commit }, transaccionId) {
    try {
      const axiosApi = new AxiosApi(Vue.prototype.$token);
      const url = apiRoute.transaccionDetalle.replace('{id}', transaccionId);
      const response = await axiosApi.sendRequestExternal(url, 'get');

      if (response.status !== 200) {
        throw new Error('Error en la respuesta de la API de detalle de transacción: ' + response.status);
      }
      const jsonData = response.data;
      transaccionDetalleData(commit, jsonData);
    } catch (error) {
      console.log(error, 'error');
      console.error('Error al obtener el detalle de la transacción:', error);
    }
  },
};

function transaccionData(commit, jsonData) {
  const data = jsonData.data;
  const dataTransacciones = [];

  data.forEach(item => {
    const transaccionObj = {
      id: item.id,
      ref_pedido: item.ref_pedido,
      cliente_id: item.cliente_id,
      ente: item.ente,
      entidad_id: item.entidad_id,
      tipo: item.tipo,
      estado: item.estado,
      total: item.total,
      metodo_pago: item.metodo_pago,
      fecha: item.fecha,
    };
  console.log('transaccionObj:', transaccionObj);


    dataTransacciones.push(transaccionObj);
  });

  commit("transacciones/setTransacciones", dataTransacciones, { root: true });
}

function transaccionDetalleData(commit, jsonData) {
  const data = jsonData.data;
  const dataTransaccionDetalle = [];

  data.forEach(item => {
    const detalleObj = {
      id: item.id,
      transaccion_id: item.transaccion_id,
      articulo: item.articulo,
      cantidad: item.cantidad,
      precio_unitario: item.precio_unitario,
      total: item.total,
    };

    dataTransaccionDetalle.push(detalleObj);
  });

  commit("transacciones/setTransaccionDetalle", dataTransaccionDetalle, { root: true });
}
