export default {
    
    getClienteLocal(state) {
        return state.clienteLocal;
    },

    getSourceVentaElementosAduana(state) {
        return state.sourceVentaElementosAduanaSt;
    }
};

