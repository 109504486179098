<template>
	<v-list-item
		:href="href"
		:rel="href && href !== '#' ? 'noopener' : undefined"
		:target="href && href !== '#' ? '_blank' : undefined"
		:to="item.to"
		:active-class="`primary ${!isDark ? 'black' : 'white'}--text`"
	>
		<v-list-item-icon
			v-if="text"
			class="v-list-item__icon--text mr-0"
			:class="'rounded rounded-r-0 ' + item.class"
			style="margin-top: 13px !important; height: 20px"
			v-html="computedText"
		/>

		<!--	:class="'rounded rounded-r-0 ' + item.class"-->
		<!--icono de los grupos de menu-->
		<v-list-item-icon
			v-else-if="item.icon"
			style="margin-right: 0px !important"
		>
			<v-icon v-text="item.icon" style="font-size: 14px; margin-top: 4px" />
		</v-list-item-icon>

		<v-list-item-content v-if="item.title || item.subtitle">
			<v-list-item-title
				v-text="item.title"
				class="pl-1"
				:class="'rounded rounded-l-0 ' + item.class"
				style="height: 20px; padding-top: 2px"
			/>
			<!--:class="'rounded rounded-l-0 ' + item.class"-->
			<!--			<span style="height: 2px; opacity: 0.65" :class="item.class"></span>
-->
			<v-list-item-subtitle v-text="item.subtitle" />
		</v-list-item-content>
		<v-list-item-icon
			v-if="item.cant"
			class="v-list-item__icon--text mr-0"
			:class="'rounded  ' + item.class"
			style="margin-top: 13px !important; height: 20px"
			v-html="item.cant"
		/>
	</v-list-item>
</template>


<script>
	import Themeable from "vuetify/lib/mixins/themeable";

	export default {
		name: "Item",

		mixins: [Themeable],

		props: {
			item: {
				type: Object,
				default: () => ({
					href: undefined,
					icon: undefined,
					subtitle: undefined,
					title: undefined,
					to: undefined,
				}),
			},
			text: {
				type: Boolean,
				default: false,
			},
		},

		computed: {
			computedText() {
				if (!this.item || !this.item.title) return "";

				let text = "";

				if (this.item.icon) {
					text = "<v-icon class='" + this.item.icon + "' start></v-icon>";
				} else {
					this.item.title.split(" ").forEach((val) => {
						text += val.substring(0, 1) != "(" ? val.substring(0, 1) : "";
					});
				}

				return text;
			},
			href() {
				return this.item.href || (!this.item.to ? "#" : undefined);
			},
		},
	};
</script>
