import { apiRoute } from '../../helper/apiRoute';
import AxiosApi from '../../plugins/axiosApi';
import axios from "axios";
import Vue from 'vue';

const axiosApi = new AxiosApi();


export async function fetchAnmac({ commit }) {
	//const clientes = dataCliente;
	//commit("anmac/setClientesAnmac", clientes, { root: true });
	try {

		const axiosApi = new AxiosApi(Vue.prototype.$token);
      	const response = await axiosApi.getExternal('clientes');

		if (response.status !== 200) {
			throw new Error('Error en la respuesta de la api clientes: ' + response.status);
		}

		let jsonData = await response.data.data;
		clienteData(commit, jsonData);

	} catch (error) {
		console.error('Error al obtener los clientes de anmac:', error);
	}
}

export async function fetchAnmacEstampillas({ commit }) {
  const estampillas = dataEstampillas;
  commit("anmac/setEstampillas", estampillas, { root: true });
}

function clienteData(commit, jsonData) {
	const dataCliente = [];


	jsonData.forEach(item => {

		let personData;
		try {
		personData = JSON.parse(item.cliente_json);
		} catch (error) {
		console.error('Error al traer cliente_json:', error);
		return;
		}

		const clienteObj = {
			id: item.id,
			name: personData.firstName || '',
			lastName: personData.lastName || '',
			businessName: personData.businessName || '',
			documento: personData.identifiers?.[0]?.identifier || '',
			localidad: personData.addresses?.[0]?.address?.location || '',
			phone: personData.phones?.[0]?.phoneNumber || '',
			email: personData.emails?.[0]?.email || '',
		};

		dataCliente.push(clienteObj);

	});

	const clientes = dataCliente;
	commit("anmac/setClientesAnmac", clientes, { root: true });

}


const dataEstampillas = [
	{
		"id": "1",
		"codigo": "X-100",
		"nombre": "Estampilla - 1000",
		"precio": "1000",
		"serialStart": "4210",
		"serialEnd": "9467"
	},
	{
		"id": "2",
		"codigo": "X-103",
		"nombre": "Estampilla - 1100",
		"precio": "1100",
		"serialStart": "1706",
		"serialEnd": "6619"
	},
	{
		"id": "3",
		"codigo": "X-106",
		"nombre": "Estampilla - 1200",
		"precio": "1200",
		"serialStart": "1922",
		"serialEnd": "8438"
	},
	{
		"id": "4",
		"codigo": "X-109",
		"nombre": "Estampilla - 1300",
		"precio": "1300",
		"serialStart": "4190",
		"serialEnd": "7615"
	},
	{
		"id": "5",
		"codigo": "X-112",
		"nombre": "Estampilla - 1400",
		"precio": "1400",
		"serialStart": "4254",
		"serialEnd": "6894"
	},
	{
		"id": "6",
		"codigo": "X-115",
		"nombre": "Estampilla - 1500",
		"precio": "1500",
		"serialStart": "3495",
		"serialEnd": "5727"
	},
	{
		"id": "7",
		"codigo": "X-118",
		"nombre": "Estampilla - 1600",
		"precio": "1600",
		"serialStart": "3312",
		"serialEnd": "5821"
	},
	{
		"id": "8",
		"codigo": "X-121",
		"nombre": "Estampilla - 1700",
		"precio": "1700",
		"serialStart": "3480",
		"serialEnd": "5765"
	},
	{
		"id": "9",
		"codigo": "X-124",
		"nombre": "Estampilla - 1800",
		"precio": "1800",
		"serialStart": "1919",
		"serialEnd": "8296"
	},
	{
		"id": "10",
		"codigo": "X-127",
		"nombre": "Estampilla - 1900",
		"precio": "1900",
		"serialStart": "4760",
		"serialEnd": "7343"
	},
	{
		"id": "11",
		"codigo": "X-130",
		"nombre": "Estampilla - 2000",
		"precio": "2000",
		"serialStart": "1084",
		"serialEnd": "9762"
	},
	{
		"id": "12",
		"codigo": "X-133",
		"nombre": "Estampilla - 2100",
		"precio": "2100",
		"serialStart": "3044",
		"serialEnd": "7555"
	},
	{
		"id": "13",
		"codigo": "X-136",
		"nombre": "Estampilla - 2200",
		"precio": "2200",
		"serialStart": "1933",
		"serialEnd": "6437"
	},
	{
		"id": "14",
		"codigo": "X-139",
		"nombre": "Estampilla - 2300",
		"precio": "2300",
		"serialStart": "4144",
		"serialEnd": "7061"
	},
	{
		"id": "15",
		"codigo": "X-142",
		"nombre": "Estampilla - 2400",
		"precio": "2400",
		"serialStart": "3501",
		"serialEnd": "8327"
	},
	{
		"id": "16",
		"codigo": "X-145",
		"nombre": "Estampilla - 2500",
		"precio": "2500",
		"serialStart": "1884",
		"serialEnd": "7803"
	},
	{
		"id": "17",
		"codigo": "X-148",
		"nombre": "Estampilla - 2600",
		"precio": "2600",
		"serialStart": "4579",
		"serialEnd": "5249"
	},
	{
		"id": "18",
		"codigo": "X-151",
		"nombre": "Estampilla - 2700",
		"precio": "2700",
		"serialStart": "2515",
		"serialEnd": "7674"
	},
	{
		"id": "19",
		"codigo": "X-154",
		"nombre": "Estampilla - 2800",
		"precio": "2800",
		"serialStart": "2456",
		"serialEnd": "5502"
	},
	{
		"id": "20",
		"codigo": "X-157",
		"nombre": "Estampilla - 2900",
		"precio": "2900",
		"serialStart": "2955",
		"serialEnd": "5762"
	},
	{
		"id": "21",
		"codigo": "X-160",
		"nombre": "Estampilla - 3000",
		"precio": "3000",
		"serialStart": "2882",
		"serialEnd": "9820"
	},
	{
		"id": "22",
		"codigo": "X-163",
		"nombre": "Estampilla - 3100",
		"precio": "3100",
		"serialStart": "1862",
		"serialEnd": "8344"
	},
	{
		"id": "23",
		"codigo": "X-166",
		"nombre": "Estampilla - 3200",
		"precio": "3200",
		"serialStart": "2178",
		"serialEnd": "8278"
	},
	{
		"id": "24",
		"codigo": "X-169",
		"nombre": "Estampilla - 3300",
		"precio": "3300",
		"serialStart": "3204",
		"serialEnd": "9516"
	},
	{
		"id": "25",
		"codigo": "X-172",
		"nombre": "Estampilla - 3400",
		"precio": "3400",
		"serialStart": "3764",
		"serialEnd": "9338"
	},
	{
		"id": "26",
		"codigo": "X-175",
		"nombre": "Estampilla - 3500",
		"precio": "3500",
		"serialStart": "2647",
		"serialEnd": "8208"
	},
	{
		"id": "27",
		"codigo": "X-178",
		"nombre": "Estampilla - 3600",
		"precio": "3600",
		"serialStart": "4521",
		"serialEnd": "8906"
	},
	{
		"id": "28",
		"codigo": "X-181",
		"nombre": "Estampilla - 3700",
		"precio": "3700",
		"serialStart": "1061",
		"serialEnd": "6651"
	},
	{
		"id": "29",
		"codigo": "X-184",
		"nombre": "Estampilla - 3800",
		"precio": "3800",
		"serialStart": "3489",
		"serialEnd": "9060"
	},
	{
		"id": "30",
		"codigo": "X-187",
		"nombre": "Estampilla - 3900",
		"precio": "3900",
		"serialStart": "4241",
		"serialEnd": "6294"
	},
	{
		"id": "31",
		"codigo": "X-190",
		"nombre": "Estampilla - 4000",
		"precio": "4000",
		"serialStart": "3283",
		"serialEnd": "9268"
	},
	{
		"id": "32",
		"codigo": "X-193",
		"nombre": "Estampilla - 4100",
		"precio": "4100",
		"serialStart": "1898",
		"serialEnd": "6425"
	},
	{
		"id": "33",
		"codigo": "X-196",
		"nombre": "Estampilla - 4200",
		"precio": "4200",
		"serialStart": "1510",
		"serialEnd": "7812"
	},
	{
		"id": "34",
		"codigo": "X-199",
		"nombre": "Estampilla - 4300",
		"precio": "4300",
		"serialStart": "4663",
		"serialEnd": "5200"
	},
	{
		"id": "35",
		"codigo": "X-202",
		"nombre": "Estampilla - 4400",
		"precio": "4400",
		"serialStart": "2900",
		"serialEnd": "6583"
	},
	{
		"id": "36",
		"codigo": "X-205",
		"nombre": "Estampilla - 4500",
		"precio": "4500",
		"serialStart": "4605",
		"serialEnd": "6485"
	},
	{
		"id": "37",
		"codigo": "X-208",
		"nombre": "Estampilla - 4600",
		"precio": "4600",
		"serialStart": "4674",
		"serialEnd": "6885"
	},
	{
		"id": "38",
		"codigo": "X-211",
		"nombre": "Estampilla - 4700",
		"precio": "4700",
		"serialStart": "2175",
		"serialEnd": "6926"
	},
	{
		"id": "39",
		"codigo": "X-214",
		"nombre": "Estampilla - 4800",
		"precio": "4800",
		"serialStart": "2450",
		"serialEnd": "6126"
	},
	{
		"id": "40",
		"codigo": "X-217",
		"nombre": "Estampilla - 4900",
		"precio": "4900",
		"serialStart": "2721",
		"serialEnd": "6203"
	},
	{
		"id": "41",
		"codigo": "X-220",
		"nombre": "Estampilla - 5000",
		"precio": "5000",
		"serialStart": "2401",
		"serialEnd": "7055"
	},
	{
		"id": "42",
		"codigo": "X-223",
		"nombre": "Estampilla - 5100",
		"precio": "5100",
		"serialStart": "4650",
		"serialEnd": "7537"
	},
	{
		"id": "43",
		"codigo": "X-226",
		"nombre": "Estampilla - 5200",
		"precio": "5200",
		"serialStart": "4942",
		"serialEnd": "9388"
	},
	{
		"id": "44",
		"codigo": "X-229",
		"nombre": "Estampilla - 5300",
		"precio": "5300",
		"serialStart": "1980",
		"serialEnd": "8842"
	},
	{
		"id": "45",
		"codigo": "X-232",
		"nombre": "Estampilla - 5400",
		"precio": "5400",
		"serialStart": "1888",
		"serialEnd": "8381"
	},
	{
		"id": "46",
		"codigo": "X-235",
		"nombre": "Estampilla - 5500",
		"precio": "5500",
		"serialStart": "4856",
		"serialEnd": "7822"
	},
	{
		"id": "47",
		"codigo": "X-238",
		"nombre": "Estampilla - 5600",
		"precio": "5600",
		"serialStart": "1834",
		"serialEnd": "6932"
	},
	{
		"id": "48",
		"codigo": "X-241",
		"nombre": "Estampilla - 5700",
		"precio": "5700",
		"serialStart": "3374",
		"serialEnd": "7564"
	},
	{
		"id": "49",
		"codigo": "X-244",
		"nombre": "Estampilla - 5800",
		"precio": "5800",
		"serialStart": "3370",
		"serialEnd": "6012"
	},
	{
		"id": "50",
		"codigo": "X-247",
		"nombre": "Estampilla - 5900",
		"precio": "5900",
		"serialStart": "1106",
		"serialEnd": "7657"
	},
	{
		"id": "51",
		"codigo": "X-250",
		"nombre": "Estampilla - 6000",
		"precio": "6000",
		"serialStart": "2473",
		"serialEnd": "5967"
	},
	{
		"id": "52",
		"codigo": "X-253",
		"nombre": "Estampilla - 6100",
		"precio": "6100",
		"serialStart": "4718",
		"serialEnd": "5959"
	},
	{
		"id": "53",
		"codigo": "X-256",
		"nombre": "Estampilla - 6200",
		"precio": "6200",
		"serialStart": "3597",
		"serialEnd": "5752"
	},
	{
		"id": "54",
		"codigo": "X-259",
		"nombre": "Estampilla - 6300",
		"precio": "6300",
		"serialStart": "1437",
		"serialEnd": "8640"
	},
	{
		"id": "55",
		"codigo": "X-262",
		"nombre": "Estampilla - 6400",
		"precio": "6400",
		"serialStart": "1008",
		"serialEnd": "5895"
	},
	{
		"id": "56",
		"codigo": "X-265",
		"nombre": "Estampilla - 6500",
		"precio": "6500",
		"serialStart": "2616",
		"serialEnd": "6212"
	},
	{
		"id": "57",
		"codigo": "X-268",
		"nombre": "Estampilla - 6600",
		"precio": "6600",
		"serialStart": "1343",
		"serialEnd": "5836"
	},
	{
		"id": "58",
		"codigo": "X-271",
		"nombre": "Estampilla - 6700",
		"precio": "6700",
		"serialStart": "2132",
		"serialEnd": "7109"
	},
	{
		"id": "59",
		"codigo": "X-274",
		"nombre": "Estampilla - 6800",
		"precio": "6800",
		"serialStart": "4524",
		"serialEnd": "6809"
	},
	{
		"id": "60",
		"codigo": "X-277",
		"nombre": "Estampilla - 6900",
		"precio": "6900",
		"serialStart": "4215",
		"serialEnd": "8836"
	},
	{
		"id": "61",
		"codigo": "X-280",
		"nombre": "Estampilla - 7000",
		"precio": "7000",
		"serialStart": "3747",
		"serialEnd": "5781"
	},
	{
		"id": "62",
		"codigo": "X-283",
		"nombre": "Estampilla - 7100",
		"precio": "7100",
		"serialStart": "3150",
		"serialEnd": "8139"
	},
	{
		"id": "63",
		"codigo": "X-286",
		"nombre": "Estampilla - 7200",
		"precio": "7200",
		"serialStart": "3017",
		"serialEnd": "9467"
	},
	{
		"id": "64",
		"codigo": "X-289",
		"nombre": "Estampilla - 7300",
		"precio": "7300",
		"serialStart": "1968",
		"serialEnd": "7247"
	},
	{
		"id": "65",
		"codigo": "X-292",
		"nombre": "Estampilla - 7400",
		"precio": "7400",
		"serialStart": "1590",
		"serialEnd": "7608"
	},
	{
		"id": "66",
		"codigo": "X-295",
		"nombre": "Estampilla - 7500",
		"precio": "7500",
		"serialStart": "1009",
		"serialEnd": "5872"
	},
	{
		"id": "67",
		"codigo": "X-298",
		"nombre": "Estampilla - 7600",
		"precio": "7600",
		"serialStart": "1935",
		"serialEnd": "5517"
	},
	{
		"id": "68",
		"codigo": "X-301",
		"nombre": "Estampilla - 7700",
		"precio": "7700",
		"serialStart": "2242",
		"serialEnd": "9706"
	},
	{
		"id": "69",
		"codigo": "X-304",
		"nombre": "Estampilla - 7800",
		"precio": "7800",
		"serialStart": "1111",
		"serialEnd": "5715"
	},
	{
		"id": "70",
		"codigo": "X-307",
		"nombre": "Estampilla - 7900",
		"precio": "7900",
		"serialStart": "1780",
		"serialEnd": "7021"
	},
	{
		"id": "71",
		"codigo": "X-310",
		"nombre": "Estampilla - 8000",
		"precio": "8000",
		"serialStart": "4060",
		"serialEnd": "6621"
	},
	{
		"id": "72",
		"codigo": "X-313",
		"nombre": "Estampilla - 8100",
		"precio": "8100",
		"serialStart": "4933",
		"serialEnd": "5527"
	},
	{
		"id": "73",
		"codigo": "X-316",
		"nombre": "Estampilla - 8200",
		"precio": "8200",
		"serialStart": "1678",
		"serialEnd": "7210"
	},
	{
		"id": "74",
		"codigo": "X-319",
		"nombre": "Estampilla - 8300",
		"precio": "8300",
		"serialStart": "1258",
		"serialEnd": "7008"
	},
	{
		"id": "75",
		"codigo": "X-322",
		"nombre": "Estampilla - 8400",
		"precio": "8400",
		"serialStart": "1472",
		"serialEnd": "8624"
	},
	{
		"id": "76",
		"codigo": "X-325",
		"nombre": "Estampilla - 8500",
		"precio": "8500",
		"serialStart": "2737",
		"serialEnd": "9583"
	},
	{
		"id": "77",
		"codigo": "X-328",
		"nombre": "Estampilla - 8600",
		"precio": "8600",
		"serialStart": "4069",
		"serialEnd": "5098"
	},
	{
		"id": "78",
		"codigo": "X-331",
		"nombre": "Estampilla - 8700",
		"precio": "8700",
		"serialStart": "4924",
		"serialEnd": "9091"
	},
	{
		"id": "79",
		"codigo": "X-334",
		"nombre": "Estampilla - 8800",
		"precio": "8800",
		"serialStart": "1110",
		"serialEnd": "6711"
	},
	{
		"id": "80",
		"codigo": "X-337",
		"nombre": "Estampilla - 8900",
		"precio": "8900",
		"serialStart": "2020",
		"serialEnd": "5798"
	},
	{
		"id": "81",
		"codigo": "X-340",
		"nombre": "Estampilla - 9000",
		"precio": "9000",
		"serialStart": "3036",
		"serialEnd": "9579"
	},
	{
		"id": "82",
		"codigo": "X-343",
		"nombre": "Estampilla - 9100",
		"precio": "9100",
		"serialStart": "3240",
		"serialEnd": "7980"
	},
	{
		"id": "83",
		"codigo": "X-346",
		"nombre": "Estampilla - 9200",
		"precio": "9200",
		"serialStart": "4447",
		"serialEnd": "8918"
	},
	{
		"id": "84",
		"codigo": "X-349",
		"nombre": "Estampilla - 9300",
		"precio": "9300",
		"serialStart": "1360",
		"serialEnd": "7514"
	},
	{
		"id": "85",
		"codigo": "X-352",
		"nombre": "Estampilla - 9400",
		"precio": "9400",
		"serialStart": "2748",
		"serialEnd": "8912"
	},
	{
		"id": "86",
		"codigo": "X-355",
		"nombre": "Estampilla - 9500",
		"precio": "9500",
		"serialStart": "3459",
		"serialEnd": "7125"
	},
	{
		"id": "87",
		"codigo": "X-358",
		"nombre": "Estampilla - 9600",
		"precio": "9600",
		"serialStart": "2641",
		"serialEnd": "5565"
	},
	{
		"id": "88",
		"codigo": "X-361",
		"nombre": "Estampilla - 9700",
		"precio": "9700",
		"serialStart": "4371",
		"serialEnd": "5027"
	},
	{
		"id": "89",
		"codigo": "X-364",
		"nombre": "Estampilla - 9800",
		"precio": "9800",
		"serialStart": "4388",
		"serialEnd": "9911"
	},
	{
		"id": "90",
		"codigo": "X-367",
		"nombre": "Estampilla - 9900",
		"precio": "9900",
		"serialStart": "3770",
		"serialEnd": "6899"
	},
	{
		"id": "91",
		"codigo": "X-370",
		"nombre": "Estampilla - 10000",
		"precio": "10000",
		"serialStart": "4584",
		"serialEnd": "9648"
	},
	{
		"id": "92",
		"codigo": "X-373",
		"nombre": "Estampilla - 10100",
		"precio": "10100",
		"serialStart": "4861",
		"serialEnd": "9497"
	},
	{
		"id": "93",
		"codigo": "X-376",
		"nombre": "Estampilla - 10200",
		"precio": "10200",
		"serialStart": "4687",
		"serialEnd": "5238"
	},
	{
		"id": "94",
		"codigo": "X-379",
		"nombre": "Estampilla - 10300",
		"precio": "10300",
		"serialStart": "1980",
		"serialEnd": "6039"
	},
	{
		"id": "95",
		"codigo": "X-382",
		"nombre": "Estampilla - 10400",
		"precio": "10400",
		"serialStart": "1090",
		"serialEnd": "9810"
	},
	{
		"id": "96",
		"codigo": "X-385",
		"nombre": "Estampilla - 10500",
		"precio": "10500",
		"serialStart": "2129",
		"serialEnd": "5551"
	},
	{
		"id": "97",
		"codigo": "X-388",
		"nombre": "Estampilla - 10600",
		"precio": "10600",
		"serialStart": "3633",
		"serialEnd": "6578"
	},
	{
		"id": "98",
		"codigo": "X-391",
		"nombre": "Estampilla - 10700",
		"precio": "10700",
		"serialStart": "3317",
		"serialEnd": "8194"
	},
	{
		"id": "99",
		"codigo": "X-394",
		"nombre": "Estampilla - 10800",
		"precio": "10800",
		"serialStart": "2023",
		"serialEnd": "10000"
	},
	{
		"id": "100",
		"codigo": "X-397",
		"nombre": "Estampilla - 10900",
		"precio": "10900",
		"serialStart": "1531",
		"serialEnd": "8422"
	}
];