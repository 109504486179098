//import Pusher from 'pusher-js';
//import Push from 'push.js';

const startPusher = (app, keycloak = false) => {
  if (keycloak.idToken) {

    let permisosConCanales = ["solicitudes_usuarios_list","listado_zona_descargas"  ];



        Pusher.logToConsole = true;

        const urlObject = new URL(process.env.VUE_APP_API_URL);

        let wsHost = urlObject.host.toString();

        let configPusher = {
          cluster: 'mt1',
          broadcaster: 'pusher',
          forceTLS: false,
          wsHost: wsHost,
          wsPort: 6001
        }
        console.log("configPusher");
        console.log(configPusher);


        var pusher = new Pusher(process.env.VUE_APP_PUSHER_ID, configPusher);

        pusher.connection.bind('connected', () => {
          //app.$toastr.s("Conexión exitosa al Servicio de Notificaciones", "Conectado");
          console.log("Conexión exitosa al Servicio de Notificaciones");
        });

        pusher.connection.bind('error', (err) => {
          //app.$toastr.e(`Error al conectar al Servicio de Notificaciones`, "Error de Conexión");
          //app.$toastr.e(`Error al conectar a Pusher: ${err.error.data.message}`, "Error de Conexión");
          console.error('Error al conectar a Pusher:', err);
        });



        permisosConCanales.forEach((canal) => {
          if (app.$can([canal])) {
          const channel = pusher.subscribe(canal);
          handleChannelEvents(channel, app, canal);
          }
        });

  }
};

// Función para manejar los eventos del canal
const handleChannelEvents = (channel, app, canal) => {
  channel.bind('pusher:subscription_succeeded', () => {
    //app.$toastr.s(`Suscripción exitosa al canal ${canal}`, "Suscripción Exitosa");
    console.log("Suscripción exitosa al canal ${canal}");
  });

  channel.bind('my-event-1', function (data) {
    app.$store.dispatch('addNotification', data.message);
    console.log(data.message);
    Push.Permission.request();
    Push.create( data.message.titulo, {
      body: data.message.mensaje,
      icon: 'img/acara_logo_2.jpg',
      requireInteraction: true,
      timeout: 3600000, // Timeout before notification closes automatically.
      vibrate: [100, 100, 100], // An array of vibration pulses for mobile devices.
      onClick: function () {
        console.log(this);
      }
    });
  });

  channel.bind('pusher:subscription_error', (status) => {
    app.$toastr.e(`Error al suscribirse al canal ${canal}: ${status}`, "Error de Suscripción");
    console.error(`Error al suscribirse al canal ${canal}:`, status);
  });

  // Agrega cualquier otro manejo de eventos específicos del canal aquí
};

export default startPusher;
