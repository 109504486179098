export default {

    setClientesAduana(state, clientesAduana) {
      state.clientesAduana = clientesAduana;
    },

    setClienteLocal(state, clienteAduana) {
      state.clienteLocal = clienteAduana;
    },

    setSourceVentaElementosAduana(state, valor) {
      state.sourceVentaElementosAduanaSt = valor
    }, 

    resetSourceVentaElementosAduana(state) {
      state.sourceVentaElementosAduanaSt = null;
    },
    
    addCliente(state, cliente) {
      state.clientesAduana.unshift(cliente);
    },

  };
  