import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import AxiosApi from "./plugins/axiosApi"

Vue.use(Vuex);

import carrito from "./modules/carrito";
import anmac from "./modules/anmac";
import aduana from "./modules/aduana";
import saldoCC from "./modules/saldoCC";
import transacciones from "./modules/transacciones";

export default new Vuex.Store({
  modules: {
    carrito,
    anmac,
    aduana,
    saldoCC,
    transacciones,
  },

  plugins: [createPersistedState()],
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    /*barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',*/
    barImage: "",
    drawer: null,
    keyDrawer:0,    
    authenticated: false,
    user: {
      id:null,
      username:null,
      name:null,
      roles: Array,
      permisos: Array,
      entidades: Array,
      notificaciones: [],
      sapProperties:{},
    },
    cartEditPath: null,
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_BAR_COLOR(state, payload) {
      state.barColor = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    async SET_USER (state, payload){
      try{
        let userId = payload.tokenParsed.sub;
        state.user.id = userId;
        state.user.username = payload.tokenParsed.preferred_username;
        state.user.name = payload.tokenParsed.given_name +
        " " +
        payload.tokenParsed.family_name;
        const client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
        if(payload.tokenParsed.groups){
          const roleUser = payload.tokenParsed.groups.filter(item => item.substring(item.indexOf(' - ')+3) == client)
          .map(item => item.substring(1, item.indexOf(' - ')));
          state.user.roles = roleUser;
        }
        else{
          state.user.roles = [];
        }
        state.user.permisos = [];
        //console.log("state.user.permisos");
        //console.log(payload);
        if(payload.resourceAccess){
          for (const key in payload.resourceAccess) {
              if(key == client){
                state.user.permisos = payload.resourceAccess[key].roles;
                break;
              }
          }
        }
        else{
          state.user.permisos = [];
        }
        //busco la/s entidad/es
        if(state.user.entidades.length < 1)
        {
            let axiosApi = new AxiosApi(payload.token)
            axiosApi.getByCriteria('usuarios_entidades', userId+'/'+client)
            .then((r) => {
              if(r.data.data){
  
                let arrayEntidades = r.data.data;
                let entidades = [];
                for (const k in arrayEntidades) {
  
                  entidades.push(arrayEntidades[k].entidad_id);
  
                }
                state.user.entidades = entidades;
              }
              else{
                state.user.entidades = []
              }
  
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        //datos de usuarios
        let axiosApiUser = new AxiosApi(payload.token)
        axiosApiUser.getByCriteria('usuarios', userId)
            .then((r) => {
              if(r.data.data){
                 state.user.userInfo = r.data.data;
              }
  
            })
            .catch(function (error) {
              console.log(error);
            });
  
        //state.user.notificaciones = [];
        let axiosApi = new AxiosApi(payload.token)
            axiosApi.getByCriteria('notificacionesByUser', userId)
            .then((r) => {
              if(r.data.data){
                state.user.notificaciones = r.data.data;
              }
              else{
                state.user.notificaciones = []
              }
            })
            .catch(function (error) {
              console.log(error);
            }); 
            
            return 1;
      }
      catch(error){
        return error;
      }
      

    },
    async SET_DATA_SAP(state, payload){
      try{
       
        const respSap = async (dataUser) => {
          let username = dataUser.tokenParsed.preferred_username; 
          
          let params = username.toUpperCase() + "/TST_MJV20";
          
          let axiosApiUser = new AxiosApi(payload.token)
          const response = await axiosApiUser.getByCriteria(
            'bussinessPartnerClient',
            params
          );
  
          return response.data.data;
        };      
        let resp = await respSap(payload);      
        if (resp) {
          state.user.sapProperties = resp;
          //console.log("state.user.sapProperties");
          //console.log(state.user.sapProperties);
          state.keyDrawer = + 1;
          
          
        } else {
          state.user.sapProperties = {};
          state.keyDrawer = + 1;
          
          
        }
      }
      catch (error) {
        console.log(error);
      }
      
    },
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_CART_EDIT_PATH(state, value) {
      state.cartEditPath = value;
    },
    RESET_USER (state){
      state.user.id = null;
      state.user.username = null,
      state.user.name = null;
      state.user.roles =[];
      state.user.permisos = [];
      state.user.entidades = [];
      state.user.notificaciones = [];
      state.user.userInfo = [];

    },
    ADD_NOTIFICATION(state, notification) {
      state.user.notificaciones.push(notification);
    }
  },
  
    
  
  actions: {    
    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification);
    },
    logout({ commit }) {
      commit('RESET_USER',{})
      commit('SET_AUTHENTICATED',false)

    },
    async login({ commit }) {
      let client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
      if(Vue.prototype.$keycloak)
      {
        let userId = Vue.prototype.$keycloak.tokenParsed.sub;
        
          await commit('SET_DATA_SAP',Vue.prototype.$keycloak);                
          await commit('SET_USER',Vue.prototype.$keycloak);        
          commit('SET_AUTHENTICATED',true)
        
        
      }
    },

    
    
  },
  getters: {
    isLogged: (state) => !!state.user,
    authenticated(state) {
      return state.authenticated;
    },
  },
});
