export async function fetchItemsSaldo({ commit }) {
  const itemsSaldo = dataItemsSaldo;
  commit("saldoCC/setSaldo", itemsSaldo, { root: true });
}

const dataItemsSaldo = [
	{
	  id: 1,
	  codigo: "P1",
	  nombre: "INGRESO DE DINERO A SU FAVOR EN MJ",
	  precio: "",
	},
	{
	  id: 2,
	  codigo: "P2",
	  nombre: "INGRESO DE DINERO A SU FAVOR EN ACARA",
	  precio: "",
	},
	{
	  id: 3,
	  codigo: "P3",
	  nombre: "INGRESO DE DINERO A SU FAVOR EN ANMAC",
	  precio: "",
	},
	{
	  id: 4,
	  codigo: "P4",
	  nombre: "INGRESO DE DINERO A SU FAVOR EN ANSV",
	  precio: "",
	},
  ];