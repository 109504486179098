import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import '/public/css/style.css'

Vue.use(Vuetify)

const theme = {
  
  danger: "#FF5252", 
  color: "#FFFFFF",
  //Design System Colours
  primary: '#1976D2',  
  secondary: '#424242', 
  accent: '#82B1FF', 
  error: '#FF5252',
  info: '#2196F3', 
  success: '#4CAF50',
  warning: '#FFC107',
  background: '#e5f3ff', 
  actionPrimary: '#0078d4',
  actionSecondary: '#e5f3ff',
  stateSuspended: '#005a9e',
  btnPrimary: '#0078d4',
  btnSecondary: '#6c757d',
  btnInactive: '#6c757d',
  tableBorder: '#dcdcdc',
  tableBackground: '#dcdcdc',

  //Para los css de los componente (vuetify2 ~ vuetify3 primeras versiones)
  'danger-base': "#FF5252", 
  'color-base': "#FFFFFF",
  //Design System Colours
  'primary-base': '#1976D2',  
  'secondary-base': '#424242', 
  'accent-base': '#82B1FF', 
  'error-base': '#FF5252',
  'info-base': '#2196F3', 
  'success-base': '#4CAF50',
  'warning-base': '#FFC107',
  'background-base': '#e5f3ff', 
  'actionPrimary-base': '#0078d4',
  'actionSecondary-base': '#e5f3ff',
  'stateSuspended-base': '#005a9e',
  'btnPrimary-base': '#0078d4',
  'btnSecondary-base': '#6c757d',
  'btnInactive-base': '#6c757d',
  'tableBorder-base': '#dcdcdc',
  'tableBackground-base': '#dcdcdc',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    options: {
      customProperties: true,
    },
    class: {
      btn__rounded: 'btn__rounded'
    },
    mini:false,
    barImage:"",
    barColor:"#080808"

  },

})
